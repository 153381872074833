// =============================================================================
// Growth Hacker LAYOUT MENU
// =============================================================================
const growthHacker = [
  {
    id: 1,
    url: '/growth-hacker/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'growth-hacker-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    url: '/growth-hacker/notifications',
    name: 'Notifications',
    i18n: 'Notifications',
    slug: 'growth-hacker-notifications',
    items: [],
    access_fulfillment: false,
    icon: 'BellIcon',
    iconLibrary: 'feather'
  },
  {
    id:3,
    header: 'Discounts Groth Hacker',
    shortHeader: 'Disc',
    i18n: 'Discounts',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/growth-hacker/promo-codes',
        name: 'Promo Codes',
        i18n: 'Promo Codes',
        slug: 'growth-hacker-promo-codes',
        icon: 'PercentIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/growth-hacker/automated-codes',
        name: 'Automated Codes',
        i18n: 'Automated Codes',
        slug: 'growth-hacker-automated-codes',
        icon: 'PercentIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/growth-hacker/exception-discounts',
        name: 'Exception Discounts',
        i18n: 'Exception Discounts',
        slug: 'growth-hacker-exception-discount',
        icon: 'PercentIcon',
        iconLibrary: 'feather'
      }
    ]
  }
]

// =============================================================================
// SALES LAYOUT MENU
// =============================================================================
const sales = [
  {
    id: 1,
    url: '/sales/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'sales-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    url: '/sales/notifications',
    name: 'Notifications',
    i18n: 'Notifications',
    slug: 'sales-notifications',
    items: [],
    access_fulfillment: false,
    icon: 'BellIcon',
    iconLibrary: 'feather'
  },
  {
    id: 3,
    header: 'Operations Sales',
    shortHeader: 'OP',
    i18n: 'Operations',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/sales/orders/delivery-orders',
        name: 'Delivery Orders',
        i18n: 'Delivery Orders',
        slug: 'sales-delivery-orders-view',
        icon: 'PackageIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/sales/orders/returns',
        name: 'Returns',
        i18n: 'Returns',
        slug: 'sales-returns-view',
        icon: 'BoxIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/sales/orders/exchanges',
        name: 'Exchanges',
        i18n: 'Exchanges',
        slug: 'growth-hacker-exception-discount',
        icon: 'RepeatIcon',
        iconLibrary: 'feather'
      },
      {
        id: 4,
        url: '/sales/orders/cash-collections',
        name: 'Cash Collection',
        i18n: 'Cash Collection',
        slug: 'sales-cash-collections-view',
        icon: 'DollarSignIcon',
        iconLibrary: 'feather'
      },
      {
        id: 5,
        url: '/sales/orders/supplies',
        name: 'Cash Collection',
        i18n: 'Cash Collection',
        slug: 'sales-supplies',
        icon: 'PackageIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 4,
    header: 'Accounts Sales',
    shortHeader: 'AC',
    i18n: 'Accounts',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/sales/pricing-packages',
        name: 'Pricing Packages',
        i18n: 'Pricing Packages',
        slug: 'sales-accounts-pricing-packages',
        icon: 'DollarSignIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/sales/merchants',
        name: 'Merchants',
        i18n: 'Merchants',
        slug: 'sales-accounts-merchants',
        icon: 'UserCheckIcon',
        iconLibrary: 'feather'
      }
    ]
  }
]

// =============================================================================
// Finance LAYOUT MENU
// =============================================================================
const finance = [
  {
    id: 1,
    url: '/accounting/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'finance-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    url: '/accounting/notifications',
    name: 'Notifications',
    i18n: 'Notifications',
    slug: 'finance-notifications',
    items: [],
    access_fulfillment: false,
    icon: 'BellIcon',
    iconLibrary: 'feather'
  },
  {
    id: 3,
    header: 'Financials',
    shortHeader: 'FI',
    i18n: 'Financials',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/accounting/pricing-packages',
        name: 'Pricing Packages',
        i18n: 'Pricing Packages',
        slug: 'pricing-packages-finance',
        icon: 'DollarSignIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/accounting/refund-requests',
        name: 'Refund Requests',
        i18n: 'Refund Requests',
        slug: 'refund-requests-finance',
        icon: 'DollarSignIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/accounting/billing/invoices',
        name: 'Invoices',
        i18n: 'Invoices',
        slug: 'invoices',
        icon: 'ClipboardIcon',
        iconLibrary: 'feather'
      },
      {
        id: 4,
        url: '/accounting/billing/statements',
        name: 'Statements',
        i18n: 'Statements',
        slug: 'statements',
        icon: 'PrinterIcon',
        iconLibrary: 'feather'
      },
      {
        id: 5,
        name: 'Payouts',
        i18n: 'Payouts',
        slug: 'statements',
        icon: 'ClipboardIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            url: '/accounting/billing/bank-deposits',
            name: 'Statement Payouts',
            i18n: 'Statement Payouts',
            slug: 'finance-accounts-bank-deposits',
            icon: 'CreditCardIcon',
            iconLibrary: 'feather'
          },
          {
            url: '/accounting/instant-payouts',
            name: 'Instant Payouts',
            i18n: 'Instant Payouts',
            slug: 'finance-instant-payouts',
            icon: 'CreditCardIcon',
            iconLibrary: 'feather'
          }
        ]
      },
      {
        id: 6,
        header: 'Discounts',
        shortHeader: 'Disc',
        i18n: 'Discounts',
        icon: 'PercentIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            id: 1,
            url: '/accounting/promo-codes',
            name: 'Promo Codes',
            i18n: 'Promo Codes',
            slug: 'finance-promo-codes',
            icon: 'PercentIcon',
            iconLibrary: 'feather'
          },
          {
            id: 2,
            url: '/accounting/automated-codes',
            name: 'Automated Codes',
            i18n: 'Automated Codes',
            slug: 'finance-automated-codes',
            icon: 'PercentIcon',
            iconLibrary: 'feather'
          },
          {
            id: 3,
            url: '/accounting/exception-discounts',
            name: 'Exception Discounts',
            i18n: 'Exception Discounts',
            slug: 'finance-exception-discounts',
            icon: 'PercentIcon',
            iconLibrary: 'feather'
          },
          {
            id: 4,
            url: '/accounting/billing/discounts',
            name: 'Credit Notes',
            i18n: 'Credit Notes',
            slug: 'finance-accounts-discounts',
            icon: 'PercentIcon',
            iconLibrary: 'feather'
          }
        ]
      },
      {
        id: 7,
        header: 'Payments',
        shortHeader: 'PA',
        i18n: 'Payments',
        icon: 'CreditCardIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            id: 1,
            url: '/accounting/payments',
            name: 'Payments',
            i18n: 'Payments',
            slug: 'finance-accounts-payments',
            icon: 'CreditCardIcon',
            iconLibrary: 'feather'
          },
          {
            id: 2,
            url: '/accounting/payment-accounts',
            name: 'Payment Accounts',
            i18n: 'Payment Accounts',
            slug: 'finance-accounts-payment-accounts',
            icon: 'UserIcon',
            iconLibrary: 'feather'
          }
        ]
      }
    ]
  },
  {
    id: 4,
    header: 'Operations Finance',
    shortHeader: 'OP',
    i18n: 'Operations',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 8,
        url: '/accounting/accounts/merchants',
        name: 'Merchants',
        i18n: 'Merchants',
        slug: 'finance-accounts-merchants',
        icon: 'UserCheckIcon',
        iconLibrary: 'feather'
      },
      {
        id: 9,
        url: '/accounting/search',
        name: 'Order Search',
        i18n: 'Order Search',
        slug: 'finance-search',
        icon: 'SearchIcon',
        iconLibrary: 'feather'
      },
      {
        id: 10,
        url: '/accounting/orders/supplies',
        name: 'Supplies Requests',
        i18n: 'Supplies Requests',
        slug: 'supplies-requests',
        icon: 'PackageIcon',
        iconLibrary: 'feather'
      },
      {
        id: 11,
        name: 'Task Lists',
        i18n: 'Task Lists',
        icon: 'MenuIcon',
        slug: 'finance-task-lists',
        iconLibrary: 'feather',
        submenu: []
      },
      {
        id: 12,
        name: 'Khazna',
        i18n: 'Khazna',
        icon: 'BriefcaseIcon',
        slug: 'finance-operations-warehouses-khazna',
        iconLibrary: 'feather',
        submenu: []
      }

    ]
  }
]

// =============================================================================
// CUSTOMER SUPPORT LAYOUT MENU
// =============================================================================
const customerSupport = [
  {
    id: 1,
    url: '/customer-support/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'customer-support-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    url: '/customer-support/accounts/customers-support',
    name: 'Customer Support',
    i18n: 'Customer Support',
    slug: 'customer-support-customers-support',
    items: [],
    icon: 'PhoneCallIcon',
    iconLibrary: 'feather'
  },
  {
    id: 3,
    header: 'Operations Customer',
    shortHeader: 'OP',     
    i18n: 'Operations',
    icon: 'PackageIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        name: 'Orders',
        i18n: 'OrdersTab',
        slug: 'customer-support-orders',
        icon: 'PackageIcon',
        iconLibrary: 'feather',
        submenu: [
          {
            url: '/customer-support/orders/delivery-orders',
            name: 'Delivery Orders',
            i18n: 'Delivery Orders',
            slug: 'customer-support-delivery-orders-view',
            icon: 'arrow-right',
            iconLibrary: 'phosphor'
          },
          {
            url: '/customer-support/orders/returns',
            name: 'Returns',
            i18n: 'Returns',
            slug: 'customer-support-returns-view',
            icon: 'arrow-left',
            iconLibrary: 'phosphor'
          },
          {
            url: '/customer-support/orders/exchanges',
            name: 'Exchanges',
            i18n: 'Exchanges',
            slug: 'customer-support-exchanges-view',
            icon: 'RepeatIcon',
            iconLibrary: 'feather'
          },
          {
            url: '/customer-support/orders/cash-collections',
            name: 'Cash Collection',
            i18n: 'Cash Collection',
            slug: 'customer-support-cash-collections-view',
            icon: 'money',
            iconLibrary: 'phosphor'
          }
        ]
      },
      {
        id: 2,
        url: '/customer-support/orders/supplies',
        name: 'Supplies Requests',
        i18n: 'Supplies Requests',
        slug: 'supplies-requests',
        icon: 'PackageIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 4,
    header: 'Fulfillment Support',
    shortHeader: 'FL',     
    i18n: 'Fulfillment',
    icon: 'Edit3Icon',
    iconLibrary: 'feather',
    access_fulfillment: false,
    items: [
      {
        id: 1,
        url: '/customer-support/fulfillment/inventory',
        name: 'Inventory',
        i18n: 'Inventory',
        slug: 'fc-operations-supervisor-dashboard',
        icon: 'ListIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 5,
    header: 'Accounts Support',
    shortHeader: 'AC',     
    i18n: 'Accounts',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/customer-support/pricing-packages',
        name: 'Pricing Packages',
        i18n: 'Pricing Packages',
        slug: 'pricing-packages',
        icon: 'DollarSignIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/customer-support/merchants',
        name: 'Merchants',
        i18n: 'Merchants',
        slug: 'merchants',
        icon: 'UserCheckIcon',
        iconLibrary: 'feather'
      }
    ]
  }
]

// =============================================================================
// Admin LAYOUT MENU
// =============================================================================
const admin = [
  {
    id: 1,
    url: '/admin/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'admin-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    url: '/admin/notifications',
    name: 'Notifications',
    i18n: 'Notifications',
    slug: 'admin-notifications',
    items: [],
    access_fulfillment: false,
    icon: 'BellIcon',
    iconLibrary: 'feather'
  },
  {
    id: 3,
    header: 'OR',
    shortHeader: 'AC',     
    i18n: 'Accounts',
    icon: 'PackageIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/admin/orders/delivery-orders',
        name: 'Delivery Orders',
        i18n: 'Delivery Orders',
        slug: 'admin-delivery-orders-view',
        icon: 'PackageIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/admin/orders/returns',
        name: 'Returns',
        i18n: 'Returns',
        slug: 'admin-returns-view',
        icon: 'UserCheckIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/admin/orders/exchanges',
        name: 'Exchanges',
        i18n: 'Exchanges',
        slug: 'admin-exchanges-view',
        icon: 'RepeatIcon',
        iconLibrary: 'feather'
      },
      {
        id: 4,
        url: '/admin/orders/cash-collections',
        name: 'Cash Collection',
        i18n: 'Cash Collection',
        slug: 'admin-cash-collections-view',
        icon: 'DollarSignIcon',
        iconLibrary: 'feather'
      },
      {
        id: 5,
        url: '/admin/orders/supplies',
        name: 'Supplies Requests',
        i18n: 'Supplies Requests',
        slug: 'admin-supplies',
        icon: 'PackageIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 4,
    header: 'Fulfillment Admin',
    shortHeader: 'FL',     
    i18n: 'Fulfillment',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/admin/fulfillment/inventory',
        name: 'Inventory',
        i18n: 'Inventory',
        slug: 'pricing-admin-fulfillment-inventory',
        icon: 'ListIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 5,
    header: 'Accounts',
    shortHeader: 'AC',     
    i18n: 'Accounts',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/admin/accounts/pricing-packages',
        name: 'Pricing Packages',
        i18n: 'Pricing Packages',
        slug: 'admin-accounts-pricing-packages',
        icon: 'DollarSignIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/admin/accounts/merchants',
        name: 'Merchants',
        i18n: 'Merchants',
        slug: 'admin-accounts-merchants',
        icon: 'UserCheckIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/admin/accounts/blacklist',
        name: 'Blacklist',
        i18n: 'Blacklist',
        slug: 'admin-blacklist',
        icon: 'UserXIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 6,
    header: 'Operations',
    shortHeader: 'OP',     
    i18n: 'Delivery Operations',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/admin/management/warehouses',
        name: 'Warehouses',
        i18n: 'Warehouses',
        slug: 'admin-management-warehouses',
        icon: 'DatabaseIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/admin/management/zone-groups',
        name: 'Zone Groups',
        i18n: 'Zone Groups',
        slug: 'admin-management-zone-groups',
        icon: 'MapPinIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/admin/management/holidays',
        name: 'Holidays',
        i18n: 'Holidays',
        slug: 'admin-holidays',
        icon: 'SmileIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 7,
    header: 'FC Operations',
    shortHeader: 'FC',     
    i18n: 'Fulfillment Operations',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/admin/fulfillment/fc-operations-supervisor',
        name: 'FC Operations Supervisor',
        i18n: 'FC Operations Supervisor',
        slug: 'admin-fc-operations-supervisor',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/admin/fulfillment/fc-receiving-agent',
        name: 'FC Receiving Agent',
        i18n: 'FC Receiving Agent',
        slug: 'admin-fc-receiving-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/admin/fulfillment/fc-inbounding-agent',
        name: 'FC Inbounding Agent',
        i18n: 'FC Inbounding Agent',
        slug: 'admin-fc-inbounding-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 4,
        url: '/admin/fulfillment/fc-storing-agent',
        name: 'FC Storing Agent',
        i18n: 'FC Storing Agent',
        slug: 'admin-fc-storing-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 5,
        url: '/admin/fulfillment/fc-packing-agent',
        name: 'FC Packing Agent',
        i18n: 'FC Packing Agent',
        slug: 'admin-fc-packing-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 6,
        url: '/admin/fulfillment/fc-picking-agent',
        name: 'FC Picking Agent',
        i18n: 'FC Picking Agent',
        slug: 'admin-fc-picking-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 8,
    header: 'Roles',
    shortHeader: 'RO',     
    i18n: 'Roles',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/admin/accounts/ops-admins',
        name: 'OPS Admins',
        i18n: 'OPS Admins',
        slug: 'admin-management-ops-admin',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/admin/accounts/commercial-admins',
        name: 'Commercial Admins',
        i18n: 'Commercial Admins',
        slug: 'admin-management-commercial-admins',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/admin/accounts/team-leaders',
        name: 'Heads Of Fleet',
        i18n: 'Heads Of Fleet',
        slug: 'admin-management-heads-of-fleet',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 4,
        url: '/admin/accounts/warehouse-agents',
        name: 'Captains',
        i18n: 'Captains',
        slug: 'admin-management-managers',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 5,
        url: '/admin/accounts/merchant-agents',
        name: 'Merchant Agents',
        i18n: 'Merchant Agents',
        slug: 'admin-merchant-agents',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 6,
        url: '/admin/accounts/customer-agents',
        name: 'Customer Agents',
        i18n: 'Customer Agents',
        slug: 'admin-customer-agents',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 7,
        url: '/admin/accounts/travellers',
        name: 'Transporters',
        i18n: 'Transporters',
        slug: 'admin-management-transporters',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 8,
        url: '/admin/accounts/customer-support',
        name: 'Customer Support',
        i18n: 'Customer Support',
        slug: 'admin-customer-support',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 9,
        url: '/admin/accounts/accounting',
        name: 'Accounting',
        i18n: 'Accounting',
        slug: 'admin-management-finances',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 10,
        url: '/admin/accounts/growth-hackers',
        name: 'Growth Hacking',
        i18n: 'Growth Hacking',
        slug: 'admin-management-growth-hacker',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 11,
        url: '/admin/accounts/sales',
        name: 'Sales',
        i18n: 'Sales',
        slug: 'admin-management-sales',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      }
    ]
  }
]

// =============================================================================
// OPERATIONS ADMIN LAYOUT MENU
// =============================================================================
const opsAdmin = [
  {
    id: 1,
    url: '/ops-admin/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'ops-admin-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    url: '/ops-admin/notifications',
    name: 'Notifications',
    i18n: 'Notifications',
    slug: 'merchant-ops-admin-notifications',
    items: [],
    access_fulfillment: false,
    icon: 'BellIcon',
    iconLibrary: 'feather'
  },
  {
    id: 3,
    header: 'Accounts Support',
    shortHeader: 'AC',     
    i18n: 'Accounts',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/ops-admin/agents/agent-training',
        name: 'Agent Training',
        i18n: 'Agent Training',
        slug: 'training',
        icon: 'SmileIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 4,
    header: 'Operations OPS',
    shortHeader: 'OP',     
    i18n: 'Delivery Operations',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/ops-admin/management/holidays',
        name: 'Holidays',
        i18n: 'Holidays',
        slug: 'ops-admin-holidays',
        icon: 'SmileIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 4,
    header: 'FC Operations OPS',
    shortHeader: 'FL',     
    i18n: 'Fulfillment Operations',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/ops-admin/fulfillment/fc-operations-supervisor',
        name: 'FC Operations Supervisor',
        i18n: 'FC Operations Supervisor',
        slug: 'ops-admin-fc-operations-supervisor',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/ops-admin/fulfillment/fc-receiving-agent',
        name: 'FC Receiving Agent',
        i18n: 'FC Receiving Agent',
        slug: 'ops-admin-fc-receiving-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/ops-admin/fulfillment/fc-inbounding-agent',
        name: 'FC Inbounding Agent',
        i18n: 'FC Inbounding Agent',
        slug: 'ops-admin-fc-inbounding-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 4,
        url: '/ops-admin/fulfillment/fc-storing-agent',
        name: 'FC Storing Agent',
        i18n: 'FC Storing Agent',
        slug: 'ops-admin-fc-storing-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 5,
        url: '/ops-admin/fulfillment/fc-packing-agent',
        name: 'FC Packing Agent',
        i18n: 'FC Packing Agent',
        slug: 'ops-admin-fc-packing-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 6,
        url: '/ops-admin/fulfillment/fc-picking-agent',
        name: 'FC Picking Agent',
        i18n: 'FC Picking Agent',
        slug: 'ops-admin-fc-picking-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      }
    ]
  },
  {
    id: 5,
    header: 'Roles OPS',
    shortHeader: 'RO',     
    i18n: 'Roles',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/ops-admin/accounts/team-leaders',
        name: 'Heads Of Fleet',
        i18n: 'Heads Of Fleet',
        slug: 'ops-admin-management-heads-of-fleet',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/ops-admin/accounts/warehouse-agents',
        name: 'Captains',
        i18n: 'Captains',
        slug: 'ops-admin-management-managers',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/ops-admin/accounts/merchant-agents',
        name: 'Merchant Agents',
        i18n: 'Merchant Agents',
        slug: 'ops-admin-merchant-agents',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 4,
        url: '/ops-admin/accounts/customer-agents',
        name: 'Customer Agents',
        i18n: 'Customer Agents',
        slug: 'ops-admin-fc-storing-agent',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 5,
        url: '/ops-admin/accounts/travellers',
        name: 'Transporters',
        i18n: 'Transporters',
        slug: 'ops-admin-management-transporters',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      }
    ]
  }
]

// =============================================================================
// COMMERCIAL ADMIN LAYOUT MENU
// =============================================================================
const commercialAdmin = [
  {
    id: 1,
    url: '/commercial-admin/dashboard',
    name: 'Dashboard',
    i18n: 'Dashboard',
    slug: 'commercial-admin-dashboard',
    items: [],
    icon: 'HomeIcon',
    iconLibrary: 'feather'
  },
  {
    id: 2,
    url: '/commercial-admin/search',
    name: 'search',
    i18n: 'search',
    slug: 'commercial-admin-search',
    items: [],
    icon: 'SearchIcon',
    iconLibrary: 'feather'
  },
  {
    id: 3,
    header: 'Roles Commercial admin',
    shortHeader: 'RO',     
    i18n: 'Roles',
    icon: 'LayersIcon',
    iconLibrary: 'feather',
    items: [
      {
        id: 1,
        url: '/commercial-admin/accounts/sales',
        name: 'Sales',
        i18n: 'Sales',
        slug: 'sales',
        icon: 'TrendingUpIcon',
        iconLibrary: 'feather'
      },
      {
        id: 2,
        url: '/commercial-admin/accounts/customer-support',
        name: 'Customer Support',
        i18n: 'Customer Support',
        slug: 'customer support',
        icon: 'PhoneCallIcon',
        iconLibrary: 'feather'
      },
      {
        id: 3,
        url: '/commercial-admin/accounts/growth-hackers',
        name: 'Growth Hacking',
        i18n: 'Growth Hacking',
        slug: 'growth hacking',
        icon: 'UsersIcon',
        iconLibrary: 'feather'
      },
      {
        id: 4,
        url: '/commercial-admin/accounts/merchants',
        name: 'Merchants',
        i18n: 'Merchants',
        slug: 'merchants',
        icon: 'UserCheckIcon',
        iconLibrary: 'feather'
      }
    ]
  }
]

export { growthHacker, sales, finance, customerSupport, admin, opsAdmin, commercialAdmin }
