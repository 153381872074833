<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">
      <div v-if="notificationPermissionModal" class="notification-container flex justify-center items-start px-2">
        <div class="bg-white px-4 pb-4 pt-2" style="border-radius: 0px 0px 17px 17px;">
          <p class="material-icons-outlined flex justify-end text-base cursor-pointer" @click="denyNotificationPermission">close</p>
          <div class="flex items-center gap-2">
            <div class="bg-primary w-fit">
              <span class="material-symbols-outlined text-white p-2 text-6xl">notifications_active</span>
            </div>
            <div>
              <p class="font-semibold text-lg text-black leading-tight">{{$t('Subscribe to ShipBlu notifications to get the important updates.')}}</p>
              <p class="font-semibold text-sm mt-2 leading-tight text-grey">{{$t('You can disable notifications anytime from your browser.')}}</p>
            </div>
          </div>
          <div class="text-right mt-4">
            <button @click="denyNotificationPermission" class="py-2 mr-4 bg-transparent border-none text-grey-dark px-6 cursor-pointer">{{$t('Later')}}</button>
            <vs-button class="py-2 px-6" @click="requestNotificationPermission">{{$t('Allow')}}</vs-button>
          </div>
        </div>
      </div>
      <div v-if="showNotificationAlert && Object.keys(activeNotification).length > 0" class="notification-container flex justify-center items-start px-2">
        <div class="w-90 bg-white px-4 pb-4 pt-2" style="border-radius: 0px 0px 17px 17px;">
          <p class="material-icons-outlined flex justify-end text-base cursor-pointer" @click="showNotificationAlert = false">close</p>
          <div class="flex items-center gap-2">
            <img src="@/assets/images/logo/logo.png" width="70" alt="">
            <div>
              <p class="font-semibold text-lg text-black leading-tight">{{ activeNotification.notification ? activeNotification.notification.title : '' }}</p>
              <p class="font-semibold text-sm mt-2 leading-tight">{{ activeNotification.notification ? activeNotification.notification.body : '' }}</p>
            </div>
          </div>
          <div class="text-right mt-4">
            <vs-button class="py-2 px-6" @click="redirctRouter(activeNotification.data)">GO!</vs-button>
          </div>
        </div>
      </div>

      <div id="content-area" v-if="updatedSidebarRoles.includes($store.state.AppActiveUser.userRole)">
        <div class="content-wrapper">
          <div class="flex items-start">
            <Sidebar :navMenuItems="selectUserNavItems" @toggleSidebar="isExpanded = $event" class="flex-shrink-0"></Sidebar>
            <div class="py-[32px] lg:px-[24px] px-[16px] h-[100vh] overflow-auto flex-grow sm:mb-0 mb-[86px]" :style="`${ isExpanded ? 'width: calc(100% - 270px)' : 'width: calc(100% - 82px)'}`" id="scrollContainer">
              <transition :name="routerTransition">
                <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                  <div v-if="routeTitle" class="content-area__heading mr-4" :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                    <h2 class="mb-1">{{ $t(routeTitle) }}</h2>
                  </div>
                  <!-- BREADCRUMB -->
                  <vx-breadcrumb class="md:block hidden" :i18nData="i18nData" :store="$store" v-if="$route.meta.breadcrumb" :route="$route" :lang="$i18n.locale" :isRTL="$vs.rtl" />
                </div>
              </transition>

              <div class="back-to-top hidden" :class="userHasScrolled ? 'show-scroll' : 'hide-scroll'">
                <vs-button @click="toTop" icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
              </div>
              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
              </transition>
            </div>
          </div>
        </div>
      </div>

      <v-nav-menu
        v-else
        :navMenuItems = "navMenuItems"
        title         = "ShipBlu"
        parent        = ".layout--main"
      />

      <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]" v-if="!updatedSidebarRoles.includes($store.state.AppActiveUser.userRole)">
      <div id="content-overlay" />

      <the-navbar-vertical
        :navbarColor="navbarColor"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />

        <div class="content-wrapper">

          <div class="router-view">
            <div class="router-content">

              <transition :name="routerTransition">

                <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                  <div
                    v-if="routeTitle"
                    class="content-area__heading mr-4"
                    :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                    <h2 class="mb-1">{{ $t(routeTitle) }}</h2>
                  </div>

                  <!-- BREADCRUMB -->
                  <vx-breadcrumb class="md:block hidden" :store="$store" :i18nData="i18nData" v-if="$route.meta.breadcrumb" :route="$route" :lang="$i18n.locale" :isRTL="$vs.rtl" />
                  
                </div>
              </transition>

              <div class="content-area__content">
                <div class="back-to-top hidden" :class="userHasScrolled ? 'show-scroll' : 'hide-scroll'">
                  <vs-button @click="toTop" icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
                </div>
                <vs-alert v-if="!isOnline" icon-pack="feather" icon="icon-alert-circle" color="warning" class="mb-base">{{$t('App is running in offline mode.')}}</vs-alert>
                <transition :name="routerTransition" mode="out-in">
                  <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
                </transition>
              </div>
            </div>
          </div>
        </div>
        <the-footer />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/layouts/merchant/Sidebar.vue'
import navMenuItems from '@/layouts/admin/navMenuItems.js'
import { growthHacker, sales, finance, customerSupport, admin, opsAdmin, commercialAdmin } from '@/layouts/admin/newNavMenuItems.js'
import TheNavbarVertical from '@/layouts/admin/navbar/TheNavbarVertical.vue'
import TheFooter from '@/layouts/components/TheFooter.vue'
import themeConfig from '@/../themeConfig.js'
import VNavMenu from '@/layouts/admin/VerticalNavMenu.vue'
import i18nData from '../../i18n/i18nData.js'
import common from '@/assets/utils/common'
import { sendRequest } from '../../http/axios/requestHelper.js'
import VueJwtDecode from 'vue-jwt-decode'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

export default {
  components: {
    TheFooter,
    TheNavbarVertical,
    VNavMenu,
    Sidebar
  },
  data () {
    return {
      dynamicWatchers   : {},
      footerType        : themeConfig.footerType  || 'static',
      hideScrollToTop   : themeConfig.hideScrollToTop,
      isNavbarDark      : false,
      navbarColor       : themeConfig.navbarColor || '#fff',
      navbarType        : themeConfig.navbarType  || 'floating',
      navMenuItems,
      growthHacker,
      sales,
      admin,
      finance,
      customerSupport,
      commercialAdmin,
      opsAdmin,
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle,
      i18nData,
      headOfFleet       : {},
      captain           : {},
      warehousesDic     : {},
      enableFulfillment : process.env.VUE_APP_ENABLE_FULFILLMENT,
      userHasScrolled   : false,
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      showNotificationAlert: false,
      activeNotification: {},
      notificationPermissionModal: false,
      userEmail: '',
      notificationPermission: '',
      userRolesDic: {
        'admin': 'admins',
        'head-of-fleet': 'heads-of-fleet',
        'finance': 'finances',
        'manager': 'managers',
        'customer-support': 'customers-support',
        'growth-hacker': 'growth-hackers',
        'sales': 'sales',
        'ops-admin': 'ops-admins',
        'commercial-admin': 'commercial-admins'
      },
      isExpanded: true,
      updatedSidebarRoles: ['growthHacker', 'sales', 'finance', 'customerSupport', 'admin', 'ops-admin', 'commercial-admin']
    }
  },
  watch: {
    notificationPermission (val) {
      if (val === 'granted') {
        this.loadUsersData()
      }
    },
    '$route' () {
      this.routeTitle = this.$route.meta.pageTitle
    },
    '$route.name' (val) {
      if (val === 'head-of-fleet-performance' && this.releaseVersion !== 'staging') {
        this.$mixpanel.track('DASH_OPERATIONS_TAB_OPENED', {
          team_leader_name: this.$store.state.AppActiveUser.displayName
        })
      }
    }
  },
  computed: {
    bodyOverlay () { return this.$store.state.bodyOverlay },
    contentAreaClass () {
      if (this.mainLayoutType === 'vertical') {
        if      (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
      }
    },
    footerClasses () {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage () {
      return this.$route.meta.no_scroll
    },
    isThemeDark ()     { return this.$store.state.theme === 'dark' },
    layoutTypeClass () { return `main-${this.mainLayoutType}`      },
    mainLayoutType ()  { return this.$store.state.mainLayoutType   },
    navbarClasses ()   {
      return {
        'navbar-hidden'   : this.navbarType === 'hidden',
        'navbar-sticky'   : this.navbarType === 'sticky',
        'navbar-static'   : this.navbarType === 'static',
        'navbar-floating' : this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth },
    windowWidth () { return this.$store.state.windowWidth },
    selectUserNavItems () {
      let items = []
      switch (this.$store.state.AppActiveUser.userRole) {
      case 'sales':
        items = this.sales
        break
      case 'growth-hacker':
        items = this.growthHacker
        break
      case 'finance':
        items = this.finance
        break
      case 'customer-support':
        items = this.customerSupport
        break
      case 'commercial-admin':
        items = this.commercialAdmin
        break
      case 'ops-admin':
        items = this.opsAdmin
        break
      case 'admin':
        items = this.admin
        break
      }
      return items
    }
  },
  methods: {
    denyNotificationPermission () {
      this.notificationPermissionModal = false
      this.$cookies.set('requestNotification', true, '1w')
    },
    requestNotificationPermission () {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission().then(() => {
          this.notificationPermissionModal = false
        })
      }
    },
    checkNotificationPermission () {
      this.notificationPermissionModal = this.notificationPermission === 'default' && this.$cookies.get('requestNotification') === null
    },
    redirctRouter (notification) {
      if (notification && notification.url !== null && notification.url.includes('s3.amazonaws.com')) {
        if (notification.url.startsWith('https') || notification.url.startsWith('http')) {
          window.open(notification.url, '_self')
        } else {
          this.$router.push({
            path: `/${this.$i18n.locale}${notification.url}`
          }).catch(() => {})
        }
        this.showNotificationAlert = false
      }
    },
    toTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    formatWarehouses (warehouses) {
      this.warehouses = warehouses
      this.warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.id] = {
          name: warehouse.name,
          code: warehouse.code
        }
      })
      switch (this.$store.state.AppActiveUser.userRole) {
      case 'head-of-fleet':
        this.loadHeadOfFleet()
        break
      case 'manager':
        this.loadCaptain()
        break
      case 'finance':
        this.loadFinance()
        break
      default:
        break
      }
    },
    loadHeadOfFleet () {
      sendRequest(false, false, this, `api/v1/users/heads-of-fleet/${this.$store.state.AppActiveUser.uid}/`, 'get', null, true,
        (response) => {
          this.headOfFleet = response.data
          if (this.headOfFleet.primary_warehouse) {
            localStorage.setItem('PrimaryWarehouse', this.warehousesDic[this.headOfFleet.primary_warehouse].code)
            let multiblewarehouses = localStorage.getItem('multipleWarehouses')
            multiblewarehouses = false
            localStorage.setItem('multipleWarehouses', multiblewarehouses)
            this.navMenuItems = [
              {
                url: '/team-leader/dashboard',
                name: 'Dashboard',
                i18n: 'Dashboard',
                icon: 'HomeIcon',
                slug: 'head-of-fleet-dashboard'
              },
              {
                url: '/team-leader/search',
                name: 'Search',
                i18n: 'Search',
                icon: 'SearchIcon',
                slug: 'head-of-fleet-search'
              },
              {
                header: `${(this.warehousesDic[this.headOfFleet.primary_warehouse].name)} Warehouse`,
                i18n: `${(this.warehousesDic[this.headOfFleet.primary_warehouse].name)} Warehouse`,
                icon: 'PackageIcon',
                role: 'head-of-fleet',
                items: [
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/first-mile-orders`,
                    name: 'First Mile Orders',
                    i18n: 'First Mile Orders',
                    icon: 'BoxIcon',
                    slug: 'head-of-fleet-delivery-orders'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/last-mile-orders`,
                    name: 'Last Mile Orders',
                    i18n: 'Last Mile Orders',
                    icon: 'BoxIcon',
                    slug: 'head-of-fleet-delivery-orders'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/returns`,
                    name: 'Returns',
                    i18n: 'Returns',
                    icon: 'BoxIcon',
                    slug: 'head-of-fleet-returns'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/cash-collections`,
                    name: 'Cash Collections',
                    i18n: 'Cash Collections',
                    icon: 'DollarSignIcon',
                    slug: 'head-of-fleet-cash-collection'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/supplies`,
                    name: 'Supplies Requests',
                    i18n: 'Supplies Requests',
                    slug: 'head-of-fleet-supplies',
                    icon: 'PackageIcon'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/task-lists`,
                    name: 'Task Lists',
                    i18n: 'Task Lists',
                    slug: 'task-lists',
                    icon: 'MenuIcon'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/in-house-orders`,
                    name: 'In-House Orders',
                    i18n: 'In-House Orders',
                    slug: 'task-lists',
                    icon: 'HomeIcon'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/khazna`,
                    name: 'Khazna',
                    i18n: 'Khazna',
                    icon: 'BriefcaseIcon',
                    slug: 'head-of-fleet-khazna'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/agents-transactions`,
                    name: 'head-of-fleet-agents-transactions',
                    i18n: 'Agents Transactions',
                    icon: 'BriefcaseIcon',
                    slug: 'head-of-fleet-khazna'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/slots`,
                    name: 'Slots',
                    i18n: 'Slots',
                    slug: 'captain-warehouse-view',
                    icon: 'GridIcon'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/bins`,
                    name: 'Bins',
                    i18n: 'Bins',
                    slug: 'captain-warehouse-view',
                    icon: 'ArchiveIcon'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/master-bags`,
                    name: 'Master Bags',
                    i18n: 'Master Bags',
                    slug: 'captin-master-bags',
                    icon: 'ShoppingBagIcon'
                  },
                  {
                    url: `/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/inventory`,
                    name: 'Supplies Inventory',
                    i18n: 'Supplies Inventory',
                    slug: 'team-leader-inventory',
                    icon: 'CpuIcon'
                  },
                  {
                    name: 'Re-zoning Section',
                    i18n: 'Re-zoning Section',
                    role: 'head-of-fleet',
                    submenu: [
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/re-zoning`,
                        name: 'Re-zoning',
                        i18n: 'Re-zoning',
                        slug: 'team-leader-rezoning',
                        icon: 'PackageIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${this.warehousesDic[this.headOfFleet.primary_warehouse].code}/shared-zones`,
                        name: 'Shared Zones',
                        i18n: 'Shared Zones',
                        slug: 'team-leader-shared-zones',
                        icon: 'PackageIcon'
                      }
                    ]
                  }
                ]
              }
            ]
          } else {
            this.navMenuItems = [
              {
                url: '/team-leader/dashboard',
                name: 'Summary',
                i18n: 'Summary',
                icon: 'HomeIcon',
                slug: 'head-of-fleet-dashboard'
              },
              {
                url: '/team-leader/search',
                name: 'Search',
                i18n: 'Search',
                icon: 'SearchIcon',
                slug: 'head-of-fleet-search'
              }
            ]
            this.warehouses.forEach(element => {
              if (!element.is_virtual) {
                this.navMenuItems.push(
                  {
                    name: `${(element.name)} Warehouse`,
                    i18n: `${(element.name)} Warehouse`,
                    role: 'head-of-fleet',
                    submenu: [
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/dashboard`,
                        name: 'Dashboard',
                        i18n: 'Dashboard',
                        icon: 'HomeIcon',
                        slug: 'head-of-fleet-warehouse-dashboard'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/first-mile-orders`,
                        name: 'First Mile Orders',
                        i18n: 'First Mile Orders',
                        icon: 'BoxIcon',
                        slug: 'head-of-fleet-delivery-orders'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/last-mile-orders`,
                        name: 'Last Mile Orders',
                        i18n: 'Last Mile Orders',
                        icon: 'BoxIcon',
                        slug: 'head-of-fleet-delivery-orders'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/returns`,
                        name: 'Returns',
                        i18n: 'Returns',
                        icon: 'BoxIcon',
                        slug: 'head-of-fleet-returns'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/cash-collections`,
                        name: 'Cash Collections',
                        i18n: 'Cash Collections',
                        icon: 'DollarSignIcon',
                        slug: 'head-of-fleet-cash-collection'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/supplies`,
                        name: 'Supplies Requests',
                        i18n: 'Supplies Requests',
                        slug: 'head-of-fleet-supplies',
                        icon: 'PackageIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/task-lists`,
                        name: 'Task Lists',
                        i18n: 'Task Lists',
                        slug: 'task-lists',
                        icon: 'MenuIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/in-house-orders`,
                        name: 'In-House Orders',
                        i18n: 'In-House Orders',
                        slug: 'task-lists',
                        icon: 'HomeIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/merchant-agents`,
                        name: 'First Mile Agents',
                        i18n: 'First Mile Agents',
                        slug: 'head-of-fleet-merchant-agents',
                        icon: 'TruckIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/customer-agents`,
                        name: 'Last Mile Agents',
                        i18n: 'Last Mile Agents',
                        slug: 'head-of-fleet-customer-agents',
                        icon: 'TruckIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/khazna`,
                        name: 'Khazna',
                        i18n: 'Khazna',
                        icon: 'BriefcaseIcon',
                        slug: 'head-of-fleet-khazna'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/agents-transactions`,
                        name: 'head-of-fleet-agents-transactions',
                        i18n: 'Agents Transactions',
                        icon: 'BriefcaseIcon',
                        slug: 'head-of-fleet-khazna'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/zone-groups`,
                        name: 'Zone Groups',
                        i18n: 'Zone Groups',
                        slug: 'head-of-fleet-zone-groups',
                        icon: 'MapPinIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/slots`,
                        name: 'Slots',
                        i18n: 'Slots',
                        slug: 'captain-warehouse-view',
                        icon: 'GridIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/bins`,
                        name: 'Bins',
                        i18n: 'Bins',
                        slug: 'captain-warehouse-view',
                        icon: 'ArchiveIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/master-bags`,
                        name: 'Master Bags',
                        i18n: 'Master Bags',
                        slug: 'captin-master-bags',
                        icon: 'ShoppingBagIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/inventory`,
                        name: 'Supplies Inventory',
                        i18n: 'Supplies Inventory',
                        slug: 'team-leader-inventory',
                        icon: 'CpuIcon'
                      },
                      {
                        name: 'Re-zoning Section',
                        i18n: 'Re-zoning Section',
                        role: 'head-of-fleet',
                        submenu: [
                          {
                            url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/re-zoning`,
                            name: 'Re-zoning',
                            i18n: 'Re-zoning',
                            slug: 'team-leader-rezoning',
                            icon: 'PackageIcon'
                          },
                          {
                            url: `/${this.$route.params.lang}/team-leader/warehouses/${element.code}/shared-zones`,
                            name: 'Shared Zones',
                            i18n: 'Shared Zones',
                            slug: 'team-leader-shared-zones',
                            icon: 'PackageIcon'
                          }
                        ]
                      }
                    ]
                  }
                )
              }
            })
          }
        }
      )
    },
    loadCaptain () {
      sendRequest(false, false, this, `api/v1/users/managers/${this.$store.state.AppActiveUser.uid}/`, 'get', null, true,
        (response) => {
          this.captain = response.data
          if (this.captain.primary_warehouse) {
            localStorage.setItem('PrimaryWarehouse', this.warehousesDic[this.captain.primary_warehouse].code)
            let multiblewarehouses = localStorage.getItem('multipleWarehouses')
            multiblewarehouses = false
            localStorage.setItem('multipleWarehouses', multiblewarehouses)
            this.navMenuItems = [
              {
                url: '/warehouse-agent/dashboard',
                name: 'Dashboard',
                i18n: 'Dashboard',
                icon: 'HomeIcon',
                slug: 'captain-dashboard'
              },
              {
                url: '/warehouse-agent/search',
                name: 'Search',
                i18n: 'Search',
                icon: 'SearchIcon',
                slug: 'captain-search'
              },
              {
                header: `${(this.warehousesDic[this.captain.primary_warehouse].name)}`,
                i18n: `${this.warehousesDic[this.captain.primary_warehouse].name} Warehouse`,
                icon: 'PackageIcon',
                role: 'manager',
                items: [
                  {
                    url: `/warehouse-agent/warehouses/${this.warehousesDic[this.captain.primary_warehouse].code}/orders`,
                    name: 'In-house Orders',
                    i18n: 'In-house Orders',
                    slug: 'captain-orders',
                    icon: 'PackageIcon'
                  },
                  {
                    url: `/warehouse-agent/warehouses/${this.warehousesDic[this.captain.primary_warehouse].code}/cash-collections`,
                    name: 'Cash Collection',
                    i18n: 'Cash Collection',
                    slug: 'captin-cash-collections',
                    icon: 'DollarSignIcon'
                  },
                  {
                    url: `/warehouse-agent/warehouses/${this.warehousesDic[this.captain.primary_warehouse].code}/task-lists`,
                    name: 'Task Sheets',
                    i18n: 'Task Sheets',
                    slug: 'captain-task-sheets',
                    icon: 'MenuIcon'
                  },
                  {
                    url: `/warehouse-agent/warehouses/${this.warehousesDic[this.captain.primary_warehouse].code}/slots`,
                    name: 'Slots',
                    i18n: 'Slots',
                    slug: 'captain-warehouse-view',
                    icon: 'GridIcon'
                  },
                  {
                    url: `/warehouse-agent/warehouses/${this.warehousesDic[this.captain.primary_warehouse].code}/bins`,
                    name: 'Bins',
                    i18n: 'Bins',
                    slug: 'captain-warehouse-view',
                    icon: 'ArchiveIcon'
                  },
                  {
                    url: `/warehouse-agent/warehouses/${this.warehousesDic[this.captain.primary_warehouse].code}/master-bags`,
                    name: 'Master Bags',
                    i18n: 'Master Bags',
                    slug: 'captin-master-bags',
                    icon: 'ShoppingBagIcon'
                  },
                  {
                    url: `/warehouse-agent/warehouses/${this.warehousesDic[this.captain.primary_warehouse].code}/inventory`,
                    name: 'Supplies Inventory',
                    i18n: 'Supplies Inventory',
                    slug: 'captin-inventory',
                    icon: 'CpuIcon'
                  }
                ]
              }
            ]
          } else {
            this.navMenuItems = [
              {
                url: '/warehouse-agent/dashboard',
                name: 'Dashboard',
                i18n: 'Dashboard',
                icon: 'HomeIcon',
                slug: 'captain-dashboard'
              },
              {
                url: '/warehouse-agent/search',
                name: 'Search',
                i18n: 'Search',
                icon: 'SearchIcon',
                slug: 'captain-search'
              }
            ]
            this.warehouses.forEach(element => {
              if (!element.is_virtual) {
                this.navMenuItems.push(
                  {
                    name: `${(element.name)}`,
                    i18n: `${element.name} Warehouse`,
                    role: 'manager',
                    submenu: [
                      {
                        url: `/${this.$route.params.lang}/warehouse-agent/warehouses/${element.code}/orders`,
                        name: 'In-house Orders',
                        i18n: 'In-house Orders',
                        slug: 'captain-orders',
                        icon: 'PackageIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/warehouse-agent/warehouses/${element.code}/cash-collections`,
                        name: 'Cash Collection',
                        i18n: 'Cash Collection',
                        slug: 'captin-cash-collections',
                        icon: 'DollarSignIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/warehouse-agent/warehouses/${element.code}/task-lists`,
                        name: 'Task Sheets',
                        i18n: 'Task Sheets',
                        slug: 'captain-task-sheets',
                        icon: 'MenuIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/warehouse-agent/warehouses/${element.code}/sort`,
                        name: 'Sort Warehouse',
                        i18n: 'Sort Warehouse',
                        slug: 'captain-task-sheets',
                        icon: 'ShuffleIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/warehouse-agent/warehouses/${element.code}/slots`,
                        name: 'Slots',
                        i18n: 'Slots',
                        slug: 'captain-warehouse-view',
                        icon: 'GridIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/warehouse-agent/warehouses/${element.code}/bins`,
                        name: 'Bins',
                        i18n: 'Bins',
                        slug: 'captain-warehouse-view',
                        icon: 'ArchiveIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/warehouse-agent/warehouses/${element.code}/master-bags`,
                        name: 'Master Bags',
                        i18n: 'Master Bags',
                        slug: 'captin-master-bags',
                        icon: 'ShoppingBagIcon'
                      },
                      {
                        url: `/${this.$route.params.lang}/warehouse-agent/warehouses/${element.code}/inventory`,
                        name: 'Supplies Inventory',
                        i18n: 'Supplies Inventory',
                        slug: 'captin-inventory',
                        icon: 'CpuIcon'
                      }
                    ]
                  }
                )
              }
            })
          }
        }
      )
    },
    loadFinance () {
      const taskListsSubmenu = []
      const khaznaSubmenu = []
      this.warehouses.forEach(warehouse => {
        if (!warehouse.is_virtual) {
          khaznaSubmenu.push({
            url: `/accounting/management/warehouses/${warehouse.code}/khazna`,
            name: `${warehouse.code} Khazna`,
            i18n: `${warehouse.code} Khazna`,
            slug: 'finance-operations-warehouses-khazna'
          })
          taskListsSubmenu.push({
            url: `/accounting/management/warehouses/${warehouse.code}/task-lists`,
            name: `${warehouse.code} Task lists`,
            i18n: `${warehouse.code} Task lists`,
            slug: 'finance-task-lists'
          })
        }
      })

      const userNavItem = this.selectUserNavItems.find(selectUserNavItem => selectUserNavItem.id === 4)
      if (userNavItem) {
        const taskListsItems = userNavItem.items.find(item => item.id === 11)
        if (taskListsItems) taskListsItems.submenu = taskListsSubmenu

        const khaznaItems = userNavItem.items.find(item => item.id === 12)
        if (khaznaItems) khaznaItems.submenu = khaznaSubmenu
      }
    },
    changeRouteTitle (title) {
      this.routeTitle = title
    },
    toggleHideScrollToTop (val) {
      this.hideScrollToTop = val
    },
    sendFirebaseToken (data) {
      sendRequest(false, false, this, 'api/v1/users/device-tokens/', 'post', data, true,
        () => {
        }
      )
    },
    loadUsersData () {
      sendRequest(false, false, this, `api/v1/users/${this.userRolesDic[this.$store.state.AppActiveUser.userRole]}/${this.$store.state.AppActiveUser.uid}/`, 'get', null, true,
        (response) => {
          this.userEmail = response.data.email
          const messaging = getMessaging()
          onMessage(messaging, (payload) => {
            this.showNotificationAlert = true
            this.activeNotification = payload.notification
          })
          getToken(messaging, { vapidKey: process.env.VUE_APP_CLOUD_MESSAGING_ID}).then((currentToken) => {
            if (currentToken) {
              const data = {
                token: currentToken,
                email: this.userEmail
              }
              this.sendFirebaseToken(data)
            }
          })
        }
      )
    }
  },
  created () {
    if (!common.isIOS()) {
      this.notificationPermission = (typeof Notification !== 'undefined') ? Notification.permission : ''
      this.checkNotificationPermission()
    }
    const userInfo = localStorage.getItem('accessToken') ? VueJwtDecode.decode(localStorage.getItem('accessToken')) : {}
    this.$store.dispatch('updateUserInfo', {
      displayName: userInfo.name,
      email: userInfo.email,
      photoURL: userInfo.picture,
      providerId: userInfo && userInfo.sub && userInfo.sub.length > 0 ? userInfo.sub.substr(0, userInfo.sub.indexOf('|')) : '',
      uid: userInfo.sub,
      userRole: userInfo[`${process.env.VUE_APP_API_URL}/role`][0]
    })
    this.$acl.change(this.$store.state.AppActiveUser.userRole)
    const query = {
      ...this.$route.query,
      page: Number(this.$route.query.page) > 0 ? this.$route.query.page : 1
    }
    if (this.$route.query && this.$route.query.page < 0) {
      this.$router.push({
        query
      }).catch(() => {})
    }
    if (this.notificationPermission === 'granted') {
      this.loadUsersData()
    }
    localStorage.setItem('multipleWarehouses', true)
    if (this.$store.state.AppActiveUser.userRole === 'manager' || this.$store.state.AppActiveUser.userRole === 'head-of-fleet' || this.$store.state.AppActiveUser.userRole === 'finance') {
      common.manageLoadWarehouses(this.formatWarehouses, this)
    } else if (this.$store.state.AppActiveUser.userRole === 'admin') {
      if (this.enableFulfillment === 'false') {
        this.navMenuItems.splice(4, 1)
      }
    }
    if (this.$cookies.get('gotVerifyAlert') === null) {
      this.$cookies.set('gotVerifyAlert', false)
    }
    if (this.$cookies.get('language') === 'en') {
      this.$i18n.locale = 'en'
      this.$vs.rtl = false
    } else {
      this.$i18n.locale = 'ar'
      this.$vs.rtl = true
    }
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 300) {
        this.userHasScrolled = true
      } else {
        this.userHasScrolled = false
      }
    }
  }
}

</script>

<style>
iframe[title="Message from company"],
iframe[title="Close message"] {
  display: none !important;
}
#launcher {
  display: none;
}
#fc_frame {
  display: none;
}
.back-to-top {
  position: fixed;
  z-index: 99999;
  bottom: 35px;
  right: 35px;
  transition: 0.5s;
}
.hide-scroll {
  opacity: 0;
}
.show-scroll {
  opacity: 1;
}
.notification-container {
  position: fixed;
  inset: 0;
  background: rgba(72, 72, 72, 0.64);
  z-index: 9999999;
}
.w-fit {
  width: 70px;
}
</style>
